


















































import Vue from 'vue';
import APISTATE from '@/enums/APISTATE';
import { mapActions, mapGetters } from 'vuex';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';

export default Vue.extend({
  name: 'distribution-view',
  data: () => ({
    filterSchoolTerm: undefined as SchoolTermDto | undefined,

  }),
  async mounted() {
    this.updatePageTitle('Timefag fordeling');
    this.resetClassSubjectStore();
    this.resetProjectsStore();
    this.resetDistributionStore();
    this.resetTeacherStore();

    await Promise.all([
      this.loadSchoolTerms(),
      this.loadDepartments(),
      this.loadEducationalDirections(),
      this.loadSchoolSubjects(),
      this.loadProjectTypes(),
    ]);
    
    this.applyGlobalSchoolTermSelection();
  },
  methods: {
    ...mapActions(['updatePageTitle', 'updateGlobalSchoolTermSelection']),
    ...mapActions('schoolTermStore', ['loadSchoolTerms']),
    ...mapActions('classSubjectStore', ['filterClassSubjects', 'loadClassSubjectsBySchoolTerm', 'resetClassSubjectStore']),
    ...mapActions('departments', ['loadDepartments']),
    ...mapActions('educationDirectionsStore', ['loadEducationalDirections']),
    ...mapActions('schoolSubjectsStore', ['loadSchoolSubjects']),
    ...mapActions('projectTypeStore', ['loadProjectTypes']),
    ...mapActions('teachers', ['filterTeachers', 'loadTeachers', 'attachTeachersTermsAndSubjects', 'loadTeachersBySchoolTerm', 'resetTeacherStore']),
    ...mapActions('projectsStore', ['loadProjectsBySchoolTerm', 'resetProjectsStore']),
    ...mapActions('distributionStore', ['loadDistributions', 'resetDistributionStore', 'closeNullAllocationDialog', 'cancelNullAllocationDialog']),
    ...mapActions('teacherTermsStore', ['loadTeacherTermsBySchoolTerm']),
    ...mapActions("distributionStore", ["initTeacherOnSubjectAllocation", "initTeacherOnProjectAllocation"]),
    ...mapActions("classSubjectStudentTypeStore", {
      loadClassSubjectStudentTypesByTerm: "loadByTerm"
    }),
    applyGlobalSchoolTermSelection() {
      const schoolTerm = this.schoolTerms.find((q:SchoolTermDto) => q.id === this.globalSchoolTermSelection);
      if (schoolTerm) {
        this.filterSchoolTerm = schoolTerm;
      }
    },

  },
  computed: {
    ...mapGetters(['globalSchoolTermSelection']),
    ...mapGetters('schoolTermStore', ['schoolTermApiState', 'schoolTerms']),
    ...mapGetters('classSubjectStore', ['classSubjectApiState']),
    ...mapGetters('departments', {isLoadingDepartments: 'isLoading'}),
    ...mapGetters('educationDirectionsStore', {educationalDepartmentsApiState: 'state'}),
    ...mapGetters('schoolSubjectsStore', {isLoadingSchoolSubjects: 'isLoading'}),
    ...mapGetters('projectTypeStore', ['projectTypesApiState']),
    ...mapGetters('distributionStore', {isDistributionLoading: 'isLoading', showNullAllocationDialog: 'showNullAllocationDialog', nullAllocationDialog: 'nullAllocationDialog'}),
    filterSchoolTermProp() {
      if (this.filterSchoolTerm) {
        return this.filterSchoolTerm.id;
      }
      return undefined;
    },
    isLoading() {
      return this.isLoadingDepartments
        || this.isLoadingSchoolSubjects
        || this.classSubjectApiState === APISTATE.LOADING
        || this.educationalDepartmentsApiState === APISTATE.LOADING
        || this.projectTypesApiState === APISTATE.LOADING
        || this.schoolTermApiState === APISTATE.LOADING;
    },
    tabs() {
      return [
        { title: 'Planlægning', to: {name: 'Distribution'} },
        { title: 'Fjernundervisning', to: {name: 'remoteEducation'} }
      ];
    },

  },
  watch: {
    async 'filterSchoolTermProp'(newValue) {
      await Promise.all([
        this.loadClassSubjectsBySchoolTerm(newValue),
        this.loadProjectsBySchoolTerm(newValue),
        this.loadTeachersBySchoolTerm(newValue),
        this.loadTeacherTermsBySchoolTerm(newValue),
        this.loadDistributions(newValue),
        this.loadClassSubjectStudentTypesByTerm(newValue),
      ]);
      await this.attachTeachersTermsAndSubjects();
      this.updateGlobalSchoolTermSelection(newValue);

      this.filterClassSubjects();
      this.filterTeachers();
    }
  }

})
